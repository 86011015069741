.top {
  position: fixed;
  right: 1%;
  bottom: 5%;
  height: 4rem;
  width: 4rem;
  color: black;
  background-color: white;
  font-size: 2rem;
  outline: none;
  border: 4px solid black;
  border-radius: 50px;
  font-weight: bold;
  cursor: pointer;
  z-index: 999;
}

.top:hover {
  background-color: #F9C811;
  color: black;
}