:root {
  --primary-darkest: rgb(23,28,40);
  --primary-dark: rgb(28,36,50);
  --primary-light: rgb(172, 181, 207);
  --accent: #F9C811;
}

.ProjectPage > .allProjects {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-content: center;
  margin-bottom: 10rem;
}

.ProjectPage h1 {
  font-size: 2.7rem;
  font-weight: bold;
  font-family: "Source Sans Pro";
  font-weight: 700;
  padding-top: 5rem;
  color: var(--bg-color);
}

.ProjectPage img {
  height: 15rem;
  width: 25rem;
  margin: 5rem 6rem 0 1rem;
}

.project-info-container {
  padding-top: 5rem;
  padding-right: 5rem;
}

.project-info-container span {
  font-weight: bold;
}

img {
  border: 4.5px solid #F9C811;
  border-radius: 20px;
}

/* .allProjects img {
  border-radius: 10px;
  box-shadow: 20px 15px #F9C811;
}

.allProjects img:hover {
  box-shadow: 10px 10px #F9C811;
} */


.projects img {
  width: 20rem;
  height: 10rem;
  margin: 2rem 0;
}

.projects {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  place-items: center;
  margin: 0 10rem;
  padding-bottom: 2rem;
}

.project-title {
  color: var(--bg-color);
  margin-top: 3rem;
}

.project-info-container span:first-child {
  color: var(--project-description);
  font-weight: 1000px;
  font-size: 2rem;
}

.project-info-container span:nth-child(3), .project-info-container span:nth-child(5), .project-info-container span:nth-child(7) {
  font-size: 1.5rem;
  color: var(--bg-color);
}

.project-info {
  background-color: #FFFFFF;
  padding: 1rem;
  font-size: 1.2rem;
  outline: 4px solid black;
  border-radius: 30px;
}

@media screen and (max-width: 1000px) {
  .ProjectPage > .allProjects {
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
    margin-bottom: 0;
  }

  .ProjectPage h1 {
    padding-bottom: 5rem;
  }

  .project-info-container {
    margin-left: 5rem;
    margin-right: 5rem;
    padding-right: 0;
    padding-top: 0;
  }

  .ProjectPage img {
    margin: 0 0 2rem 0;
  }
  .project-info {
    margin-bottom: 10rem;
    font-size: 1.1rem;
  }
  .projects {
    display: flex;
    flex-direction: column;
  }
  .project-title {
    margin-top: 6rem;
}
}

@media screen and (max-width: 550px)  {
    .ProjectPage img {
      width: 20rem;
      height: 13rem;
    }
    .project-title {
      margin-top: 7rem;
    }
}

@media screen and (min-width: 2000px) {
  .ProjectPage > .allProjects {
    grid-template-columns: auto auto auto auto;
    max-width: 100%;
  }
}

@media screen and (max-width:999px)  {

  .ProjectPage h1 {
    font-size: 2rem;
  }
  #three {
    padding-top: 1.5rem;
  }
  .ProjectPage img {
    margin-bottom: 3rem;
  }

  .ProjectPage {
    padding-bottom: 0rem;
  }

  .description {
    padding: 1rem 0;
    margin-left: 2rem;
    margin-right: 2rem;
    font-size: 20px;
  }
}

.links > a {
  margin: 10px;
  color: var(--project-description);
  outline: 2px solid black;
  outline-color: var(--project-description);
}

.links > *, h2 {
  color: var(--project-description);
}

.links > p {
  color: var(--bg-color);
}



/* .line-effects {
  position: absolute;
  margin: 5rem;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: -1;
}

.line-effect-container.horizontal {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.horizontal-line-effect {
  position: absolute;
  left: -100%;
  width: 200%;
  height: 3px;
  background-image: linear-gradient(90deg, var(--accent), var(--primary-dark), var(--accent), var(--primary-dark), var(--accent));
}

.horizontal-line-effect.left {
  animation: horizLineEffectPulse 2s linear;
  animation-iteration-count: infinite;
}

.horizontal-line-effect.right {
  left: 0;
  right: -100%;
  animation: reverseHorizLineEffectPulse 2s linear;
  animation-iteration-count: infinite;
}

.line-effect-container.vertical {
  position: absolute;
  top: calc(2.441rem / 2);
  height: 100%;
  overflow: hidden;
}

.line-effect-container.vertical.right {
  right: 0;
}

.vertical-line-effect {
  width: 2px;
  height: 200%;
  background-image: linear-gradient(var(--accent), var(--primary-dark), var(--accent), var(--primary-dark), var(--accent));
  animation: vertLineEffectPulse 2s linear;
  animation-iteration-count: infinite;
}

@keyframes horizLineEffectPulse {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(50%);
  }
}

@keyframes reverseHorizLineEffectPulse {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(-50%);
  }
}

@keyframes vertLineEffectPulse {
  0% {
      transform: translateY(0);
  }
  100% {
      transform: translateY(-50%);
  }
} */
