#contact {
  padding-bottom: 5%;
}

@media screen and (max-width: 1000px) {
  #contact {
    padding-bottom: 10%;
  }
}
@media screen and (max-width: 500px) {
  #contact {
    padding-bottom: 15%;
  }
}

.icon--linkedin--contact:hover {
  background-color: #0077b5;
  outline-color: #0077b5;
}

.icon--github--contact:hover {
  background-color: #2ea44f;
  outline-color: #2ea44f;
}

#contact-github, #contact-linkedin, #contact-github i, #contact-linkedin i {
  outline-color: var(--project-description);
  color: var(--project-description);
}

#contact-github:hover {
  outline-color: #2ea44f;
  color: yellow;
}

#contact-linkedin:hover {
  outline-color: #0077b5;
  color: yellow;
}

#contact h1 {
  color: var(--project-description);
}

#contact h3 {
  color: var(--bg-color);
}

.container-form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  margin: 1rem;
}

.contact-form {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(230, 230, 230);
  height: 27rem;
  width: 18rem;
  padding: 3rem 2rem;
  border: 3px solid black;
}

.contact-form > * {
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  place-items: center;
  margin: 0.5rem;
}
.contact-form input {
  width: 13rem;
  height: 2rem;
  border-radius: 20px;
  border: 3px solid black;
  outline: none;
  padding-left: 1rem;
  margin: 0.5rem;
  font-weight: bold;
}


.contact-form label {
  font-weight: bold;
  font-size: 1.5rem;
}

.contact-form button {
  background-color: white;
  outline: none;
  border: 3px solid black;
  border-radius: 20px;
  font-size: 1.5rem;
  width: 15rem;
  height: 2.5rem;
  margin-top: 1rem;
  color:black;
  cursor: pointer;
  font-weight: bold;
 }

 .contact-form button:hover {
  background-color:#F9C811;
 }

 #comments {
  height: 2rem;
 }

 #response {
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 2rem;
  color: #F9C811;
 }

 @media screen and (min-height:1000px) {
  .container-form {
    height: 60vh;
  }
 }
