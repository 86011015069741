.Logo {
  background-position: center;
  background-size: cover;
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-left: 20px;
  border: 2px solid black;
  transition-property:
    outline-offset, outline-color,
    background-color;
  transition-duration: .25s;  
}

.Logo:hover {
  background-color: #F9C811;
  animation: shake .25s;
  outline-offset: 4px;
  outline: 2px solid black;
}


