.WeatherPage .project-title {
  margin-top: 7rem;
}


@media screen and (max-width: 550px) {
  
  .WeatherPage .project-title {
    margin-top: 11rem;
  }
}