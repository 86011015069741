.App {
  text-align: center;
}
main {
  overflow-x: hidden;
}

footer {
  color: black;
  position: absolute;
  position: fixed;
  width: 100%;
  bottom: 0;
  font-size: 16px;
  font-weight: bold;
  background-color: white;
  border: 3px solid black;
}

body {
  background-image: url("../../components/4880206.jpg");
  background-repeat: no-repeat;
  background-size: 168%;
  overflow-x: hidden;
}

@media screen and (max-width: 900px) {
  body {
    background-size: 350%;
  }
}
@media screen and (max-width: 500px) {
  body {
    background-size: 500%;
  }
}
@media screen and (min-width: 1750px) {
  body {
    background-size: 120%;
  }
}
@media screen and (min-width: 2250px) {
  body {
    background-size: 100%;
  }
  .ProjectPage {
    margin-top: -3rem;
    padding-top: 3rem;
  }
}

@media screen and (min-width: 3100px){
  body {
    background-size: 7000px 2005px;
  }
}

* {
  scroll-behavior: smooth;
  
}

/* .project-title {
   margin-top: 3rem;
} */