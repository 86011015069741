.CalculatorPage > h1 {
  padding: 3.5rem 0 0 0;
}

p {
  color: black;
  font-weight: bold;
}

.icon {
  width: 45px;
  height: 45px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin: 0 5px;
  font-size: 28px;
  color: black;
  border-radius: 50%;
  outline: 2px solid black;
  transition-property:
      outline-offset, outline-color,
      background-color;
  transition-duration: .25s;
}

@keyframes shake {
  10% {
      transform: rotate(15deg);
  }

  20% {
      transform: rotate(-15deg);
  }

  30% {
      transform: rotate(15deg);
  }

  40% {
      transform: rotate(-15deg);
  }
  icons {
  display: flex;
  column-gap: 25px;
}
  }

.icon:hover {
    outline-offset: 4px;
}

.icon:hover i {
    animation: shake .25s;
}

.icon--link:hover {
  background-color: black;
  outline-color: black;
}

.em-link {
  font-size: 18px;
}