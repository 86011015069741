.Welcome h1 {
  font-size: 4rem;
  font-family: "Source Sans Pro";
  font-weight: 700;
}

.Welcome > * {
  color: var(--bg-color);
}

#Welcome {
  margin-bottom: 23%;
}  

.HomePage {
  position: absolute;
  top: 17%;
}


@media screen and (min-width: 400px) and (max-width: 750px) {
  #greeting {
    width: 90%;
  }
  #Welcome {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .HomePage {
    margin-bottom: 0;
  }
}

@media screen and (min-width:1000px) {

  .description {
    margin: 2.5rem auto;
    font-weight: bold;
    font-size: 1.3rem;
    max-width: 75%;
  }
}

.wave {
  animation-name: wave-animation;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
  padding: 0 0.5rem;
}

@keyframes wave-animation {
    0% { transform: rotate( 0.0deg) }
    10% { transform: rotate(30.0deg) }
    20% { transform: rotate(-15.0deg) }
    30% { transform: rotate(25.0deg) }
    40% { transform: rotate(-10.0deg) }
    50% { transform: rotate(25.0deg) }
    60% { transform: rotate( -10.0deg) }
    70% { transform: rotate(10.0deg) }
    80% { transform: rotate( 0.0deg) }
  100% { transform: rotate( 0.0deg) }
}

.scroll-indicator {
  width: 100%;
  height: 3px;
  background: var(--grey);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  visibility: hidden;
}

.progress {
  width: 0%;
  height: 4px;
  background-color: #F9C811;
}

.mouse {
  width: 25px;
  height: 40px;
  border: 2px solid #111111 ;
  outline: 2.5px solid #EAEAEA;
  border-radius: 60px;
  position: relative;
  overflow: hidden; 
}

.mouse::before {
  content: '';
  width: 5px;
  height: 5px;
  position: absolute;
  top: 7px;
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  border-radius: 50%;
  opacity: 1;
  animation: wheel 1.3s infinite;
  -webkit-animation: wheel 1.3s infinite; 
}
.home-hero__mouse-scroll-cont {
  position: absolute;
  top: 40rem;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width: 1000px) {
  .home-hero__mouse-scroll-cont {
    position: absolute;
    top: 43rem;
    left: 50%;
    transform: translateX(-50%);
  }
  .HomePage {
    top: 10%;
  }
}
@media screen and (max-width: 800px) {
  .home-hero__mouse-scroll-cont {
    position: absolute;
    top: 45rem;
  }
  .HomePage {
    top: 5%;
  }
}
@media screen and (max-width: 600px) {
  .home-hero__mouse-scroll-cont {
    position: absolute;
    top: 48rem;
  }
  .HomePage {
    top: 5%;
  }
}
@media screen and (max-width: 450px) {
  .home-hero__mouse-scroll-cont {
    position: absolute;
    top: 50rem;
  }
}
@media screen and (max-width: 435px) {
  .home-hero__mouse-scroll-cont {
    position: absolute;
    top: 54rem;
  }
}
@media screen and (max-width: 401px) {
  .home-hero__mouse-scroll-cont {
    position: absolute;
    top: 52rem;
  }
}
@media screen and (max-width: 385px) {
  .home-hero__mouse-scroll-cont {
    position: absolute;
    top: 55rem;
  }
}

@keyframes wheel {
  to {
    opacity: 0;
    top: 27px; } 
  }

@-webkit-keyframes wheel {
  to {
    opacity: 0;
    top: 27px; } 
  }

