.TechnologyPage {
  overflow-x: hidden;
  background-color: var(--background-color);
}

#two {
  background-color: #f9f9f9;
}

span > i {
  border: 4px solid black;
  background-color: white;
}

.skills {
  font-weight: bold;
}

.TechnologiesUsedTitle > h1 {
  font-size: 2.7rem;
  font-weight: bold;
  font-family: "Source Sans Pro";
  font-weight: 700;
  color: var(--bg-color);
}

.TechnologyPage h1 {
  color: var(--bg-color);
}

@media screen and (max-width:999px)  {

  .technologies-container {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    place-items: center;
    margin: 10px 0 0 0;
    padding: 25px 0 0 0;
  }
  
  span > i {
    display: grid;
    height: 35px;
    width: 35px;
    margin: 2.5px;
    padding: 2.5px;
    font-size: 20px;
    background-color: white;
    border-radius: 50%;
    align-items: center;
    margin: 2rem;
  }
  #two {
    padding-top: 2rem;
  }

}

@media screen and (min-width:1000px) {

  span > i {
    display: grid;
    height: 50px;
    width: 50px;
    margin: 30px;
    font-size: 30px;
    border-radius: 50%;
    align-items: center;
  }

  .TechnologiesUsedTitle > h1 {
    margin: 6rem -0.3rem 0 -0.3rem;
    padding: 1rem 0;
  }

  .technologies-container {
    background-color: rgb(255, 255, 255, 0);
    margin-top: 2rem;
    margin-bottom: 8rem;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    place-items: center;
  }

  .skills {
    color: black;
    opacity: 100%;
    margin: 12px;
    font-weight: bold;
  }
}

@media screen and (max-width:1175px) {
  .technologies-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 1.5rem;
  }
}