[data-theme="light"] {
  background-color: #F9F9F9;
  background-image: url("../4880206.jpg");
  --background-color: #F9F9F9;
  --whiteblack-color: white;
  --bg-color: black;
  --contact-color: black;
  --title-color: white;
  --header-color: blue;
  --project-description: black;
  transition: background-color 1050ms ease-in-out;
}

[data-theme="dark"] {
  background-color: #0d0e13;
  background-image: url("../5631804.jpg");
  --background-color: #0d0e13;
  --whiteblack-color: black;
  --bg-color: white;
  --contact-color: yellow;
  --title-color: yellow;
  --header-color: yellow;
  --project-description: #F9C811;
  transition: background-color 1050ms ease-in-out;
}

.sc-gswNZR.kFupcu, .sc-gswNZR.iknXqg {
  box-shadow: none;
  border: 8px solid black;
} 

.sc-gswNZR.hTKWlA, .sc-gswNZR.kiqURW {
  border: 8px solid #F9C811;
}