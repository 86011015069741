  .NavBar > nav {
    background-color: white;
    padding: 9px;
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto ;
    place-items: center;
  }

  nav > .logo {
    margin-left: -2rem;
  }

  .NavBar {
    position: fixed;
    z-index: 6;
    top: 0;
    width: 100%;
    overflow: hidden;
  }


  nav > a {
    background-color: none;
    color: black;
    font-weight: bold;
    text-decoration: none;
    font-size: 19px;
  }   

  nav > h2 {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  nav  .navbar {
    display: flex;
    justify-content: flex;
  }

  li {
    list-style: none;
  }

  li > * {
    color: black;
    font-weight: bold;
    text-decoration: none;
    font-size: 19px;
  }

  .icon--github, .icon--linkedin {
    color: black;
  }

  .icon--linkedin:hover {
    background-color: #0077b5;
    outline-color: #0077b5;
    color: #F9C811;
  }

  .icon--github:hover {
    background-color: #2ea44f;
    outline-color: #2ea44f;
    color: #F9C811;
  }

  .navbar {
    position: relative;
    display: block;
    padding: 16px 0;
    margin: 0 12px;
    letter-spacing: 1px;
    font-size: 15px;
    line-height: 16px;
    font-weight: 900;
    text-transform: uppercase;
    transition: color 0.1s,background-color 0.1s,padding 0.2s ease-in;
    color: black;
  }
  .navbar::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 3px;
    left: 0;
    height: 3px;
    width: 100%;
    background-color: #F9C811;
    transform-origin: right top;
    transform: scale(0, 1);
    transition: color 0.1s,transform 0.2s ease-out;
  }

  .navbar:active::before {
    background-color: #F9C811;
  }
  .navbar:hover::before, .navbar:focus::before {
    transform-origin: left top;
    transform: scale(1, 1);
  }
  


@media screen and (max-width: 1000px) {
  .NavBar nav {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    place-items: center;
  }

  .navbar {
    margin-bottom: 1rem;
    transform: translateY(15%);
  }

  div .logo, .Logo {
    margin: 0;
  }

  .Welcome h1 {
    margin-top: 8rem;
  }
}



